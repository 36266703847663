import './App.css';
import React, { useEffect } from "react";
import ScrollReveal from 'scrollreveal';
import {Header, Navbar} from './components/Header.jsx'
import {FlagsLanguage, LogoPisoUno} from './components/FlagsLanguage';
import Footer from './components/Footer';
import ContenidoBarTender from './components/ContenidoBarTender.jsx';
import ramen from './image/ramen.jpg'
import tiradito from './image/tiradito.jpg'
import sushi from './image/sushi.jpg'

const animateWithScrollReveal = (selector, duration, origin, distance) => {
  const sr = ScrollReveal();
  sr.reveal(selector, {
      duration,
      origin,
      distance,
  });
};

function App() {
  useEffect(() => {
    animateWithScrollReveal('.top_scroll', 2000, 'left', '40px');
}, []);
  return (
    <div className="App">
      <LogoPisoUno />
      <Header />
      <FlagsLanguage />
      <Navbar />
      <div className="contenido-principal">
        <div className='contenido top_scroll'>
            <img src={ramen} alt="ramen" />
            <h2>Ramen Piso uno</h2>
            <span>Tip del chef</span>
            <p>El ramen pronunciado es un plato de fideos japonés heredado de la cocina china. Hasta 1951, en Japón el ramen se llamaba shina soba Hoy en día, se utiliza chūka soba que también significa o simplemente ramen, que es uno de los términos más populares.</p>
        </div>
        <div className='contenido top_scroll'>
            <img src={tiradito} alt="ramen" />
            <h2>Tiradito</h2>
            <span>Tip del chef</span>
            <p>El tiradito es un plato peruano de pescado crudo, cortado en forma de sashimi y de aspecto similar al carpaccio, en una salsa picante fría y ácida. Es un plato representativo de la cocina nikkei, que refleja la influencia de los inmigrantes japoneses sobre la cocina peruana​ y aunque comparte con el ceviche la «cocción» en jugo de limón difiere de éste en la forma de cortar el pescado y la ausencia de cebolla.</p>
        </div>
        <div className='contenido top_scroll'>
            <img src={sushi} alt="ramen" />
            <h2>Sushi</h2>
            <span>Tip del chef</span>
            <p>Es un plato típico de origen japonés basado en arroz aderezado con vinagre de arroz, azúcar y sal y combinado con otros ingredientes como pescados crudos, mariscos, verduras, etc. Este plato es uno de los más reconocidos de la gastronomía japonesa y uno de los más populares internacionalmente​</p>
        </div>
      </div>
      <ContenidoBarTender/>
      <Footer/>
    </div>
  );
}

export default App;
