import React from "react";
import ScrollReveal from 'scrollreveal';
import '../stylesheets/Footer.css';

function Footer() {
    return(<footer className="footer">
    <div className="footer-content">
        <p>Sitio creado por LeonardoDesigner & JesusDesigner</p>
        <div className="contacto_creadores">
        <span>Contacto:</span>
        <a target="_blank" href="https://leonardobolivarportfolio.netlify.app/"> L&JDesing</a>
    </div>
    </div>
</footer>)
}
export default Footer;