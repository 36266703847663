import React, { useEffect } from "react";
import ScrollReveal from 'scrollreveal';
import '../stylesheets/FlagsLanguage.css'

const animateWithScrollReveal = (selector, duration, origin, distance) => {
    const sr = ScrollReveal();
    sr.reveal(selector, {
        duration,
        origin,
        distance,
    });
};

function FlagsLanguage(){
    useEffect(() => {
        animateWithScrollReveal('.top_scroll', 2000, 'top', '60px');
    }, []);

    return(<div className="top_scroll" id="flags">
    <div className="flags__item" data-language="es">
        <img src={require(`../image/spain.png`)} alt="bandera españa" />
    </div>
    <div className="flags__item" data-language="en">
        <img src={require(`../image/united-kingdom.png`)} alt="bandera reino unido" />
    </div>
</div>);
}

function LogoPisoUno() {
    return(<div className="logo_pisouno top_scroll">
            <img src={require(`../image/Logo_Piso_Uno_Restaurant.png`)} alt="logo" />
        </div>)
}

export {FlagsLanguage, LogoPisoUno};