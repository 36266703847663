import React, { useEffect } from "react";
import ScrollReveal from 'scrollreveal';
import '../stylesheets/ContenidoBarTender.css';

const animateWithScrollReveal = (selector, duration, origin, distance) => {
    const sr = ScrollReveal();
    sr.reveal(selector, {
        duration,
        origin,
        distance,
    });
};
function ContenidoBarTender(){
    useEffect(() => {
        animateWithScrollReveal('.left_scroll', 2000, 'left', '40px');
        animateWithScrollReveal('.right_scroll', 2000, 'right', '40px');
    }, []);
    return(
        <section className="contenidoBar_principal container">
            <div className="parrafo_tips_bar left_scroll">
                <h2 className="tips_bartender_h2">Tips del bartender</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores cumque ad fugiat dicta tenetur aperiam necessitatibus id amet vel itaque.Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                <ul>
                    <li>
                        <h3>Mojito</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis porro architecto </p>
                    </li>
                    <li>
                        <h3>Negroni</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis porro architecto </p>
                    </li>
                    <li>
                        <h3>Fireball</h3>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis porro architecto </p>
                    </li>
                </ul>
            </div>
            <div className="Tips_bar left_scroll">
                <img className="img_tips_bar" src={require(`../image/man-1456951_640.jpg`)} alt="" />
            </div>
        </section>
    )
}
export default ContenidoBarTender;