import React, { useEffect } from "react";
import ScrollReveal from 'scrollreveal';
import '../stylesheets/Header.css';

const animateWithScrollReveal = (selector, duration, origin, distance) => {
    const sr = ScrollReveal();
    sr.reveal(selector, {
        duration,
        origin,
        distance,
    });
};

function Header() {
    useEffect(() => {
        animateWithScrollReveal('.bottom_scroll', 2000, 'bottom', '60px');
    }, []);

    return (
        <header className="header">
            <div className="header-content container">
                <span>-bienvenidos-</span>
                <h1>Piso Uno Restaurant</h1>
            </div>
        </header>
    );
}

function Navbar() {
    return (
        <nav className="navbar">
            <div className="btns_navbar">
                <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=56958633542">Delivery</a>
            </div>
            <div className="btns_navbar">
                <a target="_blank" rel="noreferrer" href="https://toto.menu/pisouno/menu">carta</a>
            </div>
            <div className="btns_navbar">
                <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/reserve/v/dine/c/JtKhs1lWk08?source=pa&hl=en-FR&gei=OpaMZMWeArenkdUP7YWc2AY&sourceurl=https://www.google.com/maps/preview/place?authuser%3D0%26hl%3Den%26gl%3Dfr%26pb%3D!1m16!1s0x9662cf67d3563f4f:0x117c17525a6bdf68!3m8!1m3!1d5671.596560567685!2d-70.6121427!3d-33.420331!3m2!1i1598!2i1308!4f13.1!4m2!3d-33.4202915!4d-70.6099986!15m2!1m1!4s/g/11fy4nwztv!12m4!2m3!1i360!2i120!4i8!13m57!2m2!1i203!2i100!3m2!2i4!5b1!6m6!1m2!1i86!2i86!1m2!1i408!2i240!7m42!1m3!1e1!2b0!3e3!1m3!1e2!2b1!3e2!1m3!1e2!2b0!3e3!1m3!1e8!2b0!3e3!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e9!2b1!3e2!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e10!2b0!3e4!2b1!4b1!9b0!14m2!1sOJaMZJ2XE52SkdUPmZGsmAo!7e81!15m82!1m29!13m9!2b1!3b1!4b1!6i1!8b1!9b1!14b1!20b1!25b1!18m18!3b1!4b1!5b1!6b1!9b1!12b1!13b1!14b1!15b1!17b1!20b1!21b1!22b0!25b1!27m1!1b0!28b0!30b0!2b1!5m6!2b1!3b1!5b1!6b1!7b1!10b1!10m1!8e3!11m1!3e1!14m1!3b1!17b1!20m2!1e3!1e6!24b1!25b1!26b1!29b1!30m1!2b1!36b1!39m3!2m2!2i1!3i1!43b1!52b1!54m1!1b1!55b1!56m2!1b1!3b1!65m5!3m4!1m3!1m2!1i224!2i298!71b1!72m4!1m2!3b1!5b1!4b1!89b1!103b1!113b1!21m28!1m6!1m2!1i0!2i0!2m2!1i530!2i1308!1m6!1m2!1i1548!2i0!2m2!1i1598!2i1308!1m6!1m2!1i0!2i0!2m2!1i1598!2i20!1m6!1m2!1i0!2i1288!2m2!1i1598!2i1308!22m1!1e81!30m3!3b1!6m1!2b1!34m2!7b1!10b1!37i650!39sPISO%2BUNO%26q%3DPISO%2BUNO">reserva</a>
            </div>
            <div className="btn-redes">
            <a target="_blank" rel="noreferrer"  href="https://instagram.com/pisounorestaurant?igshid=MzRlODBiNWFlZA==">
            <i class="fa-brands fa-square-instagram"></i>
            </a>
            </div>
        </nav>
    );
}

export { Header, Navbar };
